'use client';

import { useTranslations } from 'next-intl';

import { useRouter } from '@/hooks/useRouter';
import { StatusCodeErrors } from '@/lib/error-codes.enum';
import error404 from '@/public/images/error404.svg';
import error500 from '@/public/images/error500.svg';
import { US_GENERIC_NUMBER } from '@/utils/constants';

import { ClickablePhoneNumber } from './ClickablePhoneNumber';
import { ImageWithPlaceholder } from './ImageWithPlaceholder';
import { Link } from './Link';
import { PrimaryButton } from './buttons';
import { PrimaryHeading } from './typography';

type PageErrorProps = {
  statusCode: StatusCodeErrors;
};

export default function PageError({ statusCode }: PageErrorProps) {
  const t = useTranslations(`page-error.${statusCode.toString()}`);
  const router = useRouter();

  const handleClickCta = () => {
    if (statusCode === StatusCodeErrors.INTERNAL_SERVER) {
      window.location.reload();
    } else {
      router.push('/');
    }
  };

  return (
    <div className="flex flex-col items-center my-12 md:flex-row max-md:mt-2">
      <ImageWithPlaceholder
        className="w-full md:w-1/2 max-md:mb-8"
        src={statusCode === StatusCodeErrors.NOT_FOUND ? error404 : error500}
        width={290}
        height={221}
        alt={t('alt-text')}
        priority
      />
      <div className="flex flex-col items-center px-4 text-center md:px-10 lg:px-20">
        <PrimaryHeading className="mb-5">{t('title')}</PrimaryHeading>
        <p className="mb-6 md:mb-8">
          {t.rich('description', {
            important: (chunks) => (
              <ClickablePhoneNumber phoneNumber={chunks} />
            ),
            phoneNumber: US_GENERIC_NUMBER,
            link: (chunks) => (
              <Link className="underline" href="/contact">
                {chunks}
              </Link>
            ),
          })}
        </p>
        <PrimaryButton onClick={handleClickCta}>{t('cta')}</PrimaryButton>
      </div>
    </div>
  );
}
